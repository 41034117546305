import React from "react";
import Intro from "../components/intro/Intro";

const IntroPage = () => {
  return (
    <>
      <Intro />
    </>
  );
};

export default IntroPage;
